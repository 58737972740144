import 'react-app-polyfill/ie9';
import { st, pt, lt, it } from './texte'
import { constants, startPiwik, sendAnalyticsEvent, sendAnalyticsPage, scrollToElement, findRate } from './common';

import 'whatwg-fetch'

import Moment from 'moment';
import { extendMoment } from 'moment-range';
const moment = extendMoment(Moment);
  
  
const SendPost = (state, setState, file, params, callback) => {
  var data = new FormData();
  data.append('hostel_ident', state.hostelident);
  data.append('sessionid', state.sessionid);
  for (var k in params) {
    data.append(k, params[k]);
  }
  fetch("https://"+state.apihost+"/"+file+".php",
  {
      method: "POST",
      body: data
  })
  .then((res) => res.json())
  .then((data) => {
    if (setState !== null) {
      callback(data, setState, state);
    }
  })
}

const SendPostSync = async (state, setState, file, params, callback) => {
  var data = new FormData();
  data.append('hostel_ident', state.hostelident);
  data.append('sessionid', state.sessionid);
  for (var k in params) {
    data.append(k, params[k]);
  }
  const res = await fetch("https://"+state.apihost+"/"+file+".php",
  {
      method: "POST",
      body: data
  });
  const data2 = await res.json();
  return await callback(data2, setState, state);
}

const loadKonfigs = (state, setState, konfigLoaded, loadLang) => {
  SendPost(state, setState, 'gethostelkonfig', {priceagent:state.priceagentCode}, (data, setState, state)=>{
    if (data.hostelkonfig) {
      var stat = {konfig: data.hostelkonfig, sessionid:data.hostelkonfig.sessionid, sprachId:state.sprachId};
      data.hostelkonfig.sprachen_key = {};
      var spids = '';
      var found = false;
      for (var i=0;i<data.hostelkonfig.sprachen.length;i++) {
        var sp = data.hostelkonfig.sprachen[i];
        if (sp.abk === state.sprache) {
          found = true;
        }
        data.hostelkonfig.sprachen_key[sp.abk] = sp.bezeichnung;
        spids += ','+sp.sprache_id.toString();
      }
      if (!found) {
        stat.sprache = data.hostelkonfig.defaultsprache;
        stat.sprachId = data.hostelkonfig.defaultspracheid;
      }
      data.hostelkonfig.sprachen_ids = spids.substring(1);
      data.hostelkonfig.showaverageprice = parseInt(data.hostelkonfig.showaverageprice);
      data.hostelkonfig.pricenocomm = parseInt(data.hostelkonfig.pricenocomm);
      data.hostelkonfig.childalterskat = parseInt(data.hostelkonfig.childalterskat);
      data.hostelkonfig.childindorm = parseInt(data.hostelkonfig.childindorm);
      data.hostelkonfig.childinprivat = parseInt(data.hostelkonfig.childinprivat);
      data.hostelkonfig.bookwithchild = data.hostelkonfig.childalterskat>0 && (data.hostelkonfig.childindorm > 0 || data.hostelkonfig.childinprivat>0);
      if (state.filterErwachsene===false) {
        stat.filterErwachsene = Math.max(1, parseInt(data.hostelkonfig.defaultpersonen));
      }
      stat.is_test = data.hostelkonfig.api_test;
      
      if (state.filterAnkunft.format('YYYYMMDD') < data.hostelkonfig.firstankunft) {
        if (!state.fromWidget) {
          stat.filterAnkunft = moment(data.hostelkonfig.firstankunft);
        }
        stat.minDate = moment(data.hostelkonfig.firstankunft).toDate();
      }
      
      setState(stat, konfigLoaded)
      
      startPiwik();
      
      if (loadLang) loadLang(stat.sprachId);
    }
    else {
      setState({servererror: true});
    }
  });
  var param = {priceagent:state.priceagentCode};
  if (state.bookflag) {
    param.bookflag = state.bookflag; 
  }
  SendPost(state, setState, 'getpaymentinfo', param, (data, setState)=>{
    if (data.paymentinfo) {     
      data.paymentinfo.buchungtraditional = (parseInt(data.paymentinfo.kombuchung) === 0 || data.paymentinfo.BookingFee !== 'Stripe');
      var stat = {
        paymentinfo: data.paymentinfo,
        priceagentCode: data.paymentinfo.priceagent, 
        bookNoCC: data.paymentinfo.BookingFee === constants.bfNoPaymentInfo
      }
      if (!state.startagent) {
        stat.startagent = data.paymentinfo.priceagent;
      }
      setState(stat);
    }
    else {
      setState({servererror: true});
    }
  });
}  

const loadSprache = (state, setState, spid) => {
  SendPost(state, setState,'gettexte', {sprache_id:spid, def_sprache_id:state.konfig.defaultspracheid}, (data, setState)=>{
    for(var k in data.statisch) {
      st[k] = data.statisch[k];
    }
    lt.list = data.laender;
    it.list = data.icons;
    for(k in data.property) {
      pt[k] = data.property[k];
    }
    setState((state) => ({ renderid: state.renderid+1 }));    
  });   
}

// 46056 / test@dormproject.ch
const getBookingdata = (state, setState, rescrypt, func) => {
  SendPost(state, setState,'getbookingdata', {
      rescrypt:rescrypt?rescrypt:state.rescrypt, 
      sprache_id: state.sprachId}
  , (data, setState)=>{
    if (data.bookingData) {
      var bd = data.bookingData;
      var errorkey = '';
      if (bd.reschangefail===1) {
        errorkey = 'bwh.errormessage.changeres.resnotexist';
      }
      else if (bd.reschangefail===2) {
        errorkey = 'bwh.errormessage.changeres.ankunftinpast';
      }
      else if (bd.reschangefail===3) {
        errorkey = 'bwh.errormessage.changeres.rescanceled';
      }
      else if (bd.reschangefail===4) {
        errorkey = 'bwh.errormessage.changeres.lastchangeover';
      }
      if (errorkey !== '') {
        var error = st[errorkey]?st[errorkey]:errorkey;
        error = error.replace('{resnr}', state.login_resnr);
        error = error.replace('{canceltime}', state.konfig.canceltime);
        setState({error: error, status: '-'});
      }
      else {
        window.history.pushState && window.history.pushState({status:'change'}, 'change', '#change');        
        bd.isBooked = true;
        
        var stat = {status: 'change'};
        var ag = data.bookingData.angebot.list[0];
        for (var k in data.bookingData.personendaten) {
          stat['pf_'+k] = data.bookingData.personendaten[k];
        }
        for (k in data.bookingData.kreditkarte) {
          stat['cc_'+k] = data.bookingData.kreditkarte[k];
        }
        bd.angebot.list.sort( (a,b) => (a.ankunft > b.ankunft) ? 1 : ((a.ankunft < b.ankunft) ? -1 : 0) );
        
        stat['bookingData'] = bd;
        stat['resnr_changing'] = 0;
        stat['resnr_booked'] = parseInt(ag.resNr);
        stat['filterAnkunft'] = moment(ag.ankunft);
        stat['filterNaechte'] = parseInt(ag.naechte);
        stat['filterErwachsene'] = parseInt(ag.erwachsene);
        stat['filterKind'] = parseInt(ag.kind);
        stat['filterKind2'] = parseInt(ag.kind2);
        stat['filterCoupon'] = ag.coupon;
        stat['cancelTimeLang'] = data.bookingData.canceltime[state.sprache];
        stat['confirminfotext'] = data.bookingData.confirminfotext;
        stat['priceagentCode'] = data.bookingData.AgentCode;
        stat['bookflag'] = data.bookingData.bookflag;
        stat['hasRates'] = data.bookingData.rate_id ? parseInt(data.bookingData.rate_id) : false;
        
        var zusatzAnzahl = {};
        var agLast = data.bookingData.angebot.list[data.bookingData.angebot.list.length-1];        
        if (agLast.services) {
          for (k = 0; k<agLast.services.length;k++) {
            zusatzAnzahl[agLast.services[k].zkat] = agLast.services[k].anzahl;
          }
        }
        stat.zusatzAnzahl = zusatzAnzahl;

        var needZahlungsData = true;
        var expmonth = stat['cc_expmonth'];
        var expyear = stat['cc_expyear'];
        if ('' === expmonth || '' === expyear) {
          needZahlungsData = false;
        }
        else {
          var abreise = moment(ag.ankunft).add(ag.naechte, 'day');
          var expDate = moment(expyear + "-" + expmonth);
          if (abreise < expDate) {
            needZahlungsData = false;
          }
        }
        stat['needZahlungsData'] = needZahlungsData;
        
        var afterSet = null;
        if (state.priceagentCode !== data.bookingData.AgentCode) {
          afterSet = func;
        }
        
        setState(stat, afterSet);
      }
    }
    else {
      setState({servererror: true});
    }
  });
}

const getAngebote = (state, setState) => {
  var mf = state.filterMultiroomfilter;
  if (!mf.easy && !mf.easypriv && !mf.multiroom) {
    mf = {easy: true, easypriv:true, multiroom:true};
  }
  var gf = state.filterGeschlecht;
  if (gf.length===0 || (gf.length===1 && gf.indexOf(4)>-1)) {
    if (mf.easy || (!mf.easypriv && !mf.multiroom)) {
      gf = [2,0,1];
    }
  }
  if (gf.indexOf(4)===-1 && (mf.easypriv || !mf.easy)) {
    gf.push(4);
  }
  
  const bookwithchild = state.konfig.bookwithchild && (state.filterKind + state.filterKind2)>0
  if (bookwithchild && !state.konfig.childindorm) {
    gf = [4];
  }
  
  SendPost(state, setState, 'getdormresults', {
    sprachen: state.konfig.sprachen_ids,
    defaultsprache: state.konfig.defaultsprache,
    agent: 3,
    priceagent: state.priceagentCode,
    ankunft: state.filterAnkunft.format('YYYY-MM-DD'),
    naechte: state.filterNaechte,
    erwachsene: state.filterErwachsene,
    kind: state.filterKind,
    kind2: state.filterKind2,
    bookwithchild: bookwithchild?1:0,
    multiroomfilter: JSON.stringify({...mf, easy:true}),
    geschlecht: JSON.stringify(gf),
    coupon: state.filterCoupon,
    first: state.first,
    fromWidget: state.fromWidget,
    resnr: state.resnr_changing,
    sessionid: state.sessionid,
  }, (data, setState)=>{    
    window.history.pushState && window.history.pushState({status:'angebot'}, 'angebot', '#angebot');        

    var newState = {
      avpreview: data.avpreview,
      dormresults: data.dormresults,
      specialday: data.specialday,
      hasRates: data.hasRates,
      minstay: data.minstay,
      status: 'angebot',
    };
    if (data.neueankunft && data.neueankunft !== state.filterAnkunft.format('YYYY-MM-DD')) {
      newState.filterAnkunft = moment(data.neueankunft);
    }
    if (data.neuedauer && data.neuedauer !== state.naechte) {
      newState.filterNaechte = data.neuedauer;
    }
    setState(newState);
    
    if (state.get.openChange) {
      scrollToElement('primaryContainer', 'primaryContainer', 0, true, false);
    }
    else {
      var scoffset = state.get.scoffset;
      if (scoffset > 0 && scoffset < 600) {
        scrollToElement('suchFilter_wrap', 'suchFilter_wrap', scoffset, true, false);
      }
      else {
        scrollToElement('suchFilter_wrap', 'suchFilter_wrap', 150, true, false);
      }
    }
    
  });   
  setState({first:0});
}

const setBooking = async (state, setState, token, getBookings) => {
  var oldStatus = state.status;
  window.history.pushState && window.history.pushState({status:'setBooking'}, 'setBooking', '#setBooking');        
  setState({error:'', status: 'setBooking'});
  if (!token) token = "";
  var guestData = {}
  for(var k in state) {
    if (k.substr(0,3)==='pf_' && state[k]) {
      guestData[k.substr(3)] = state[k];
    }
  }
  var zahlungsData = {}
  for(k in state) {
    if (k.substr(0,3)==='cc_' && state[k]) {
      var x = k.substr(3);
      if (x.substr(0,3)==='exp') {
        x = x.substr(0,3)+'-'+x.substr(3);
      }
      zahlungsData[x] = state[k];
    }
    if (k.substr(0,7)==='confirm' && state[k]) {
      zahlungsData[k] = state[k]?1:0;
    }
  }                  

  var bd = state.selBooking.angebot.list[0];

  var zusatzData = {}
  for(k=0; k<bd.services.length; k++) {
    var z = bd.services[k];
    var anz = state.zusatzAnzahl[z.zkat];
    if (anz > 0) {
      zusatzData[(k+1)+'-'+z.zkat] = {
        checked: anz,
        online_type: z.online_type,
        preis: z.preis,
      };
    }
  }

  var preis = findRate(bd.rates, state.selectedRate).preis;
  var senddata = {
    sprache: state.sprache,
    priceagent: state.priceagentCode,
    guestData: JSON.stringify(guestData),
    zahlungsData: JSON.stringify(zahlungsData),
    zusatzData: JSON.stringify(zusatzData),
    bookingData: JSON.stringify({
      ankunft: bd.ankunft,
      naechte: bd.naechte,
      aklist: bd.aklist,
      geschlecht: bd.geschlecht,
      selpreiskat:bd.rooms[0].pkat,
      angId: bd.angId,
      rate_id: state.selectedRate,
      nettopreis:preis.room,
      waehrung:bd.waehrung,
      kommision:preis.commission,
      resnr: state.resnr_changing,
      rabattCode: bd.coupon,
    }),
    paymentInfo:  JSON.stringify({
      stripeToken: token,
      BookingFee: state.paymentinfo.BookingFee,
      storePaymentInfo: state.paymentinfo.storePaymentInfo,
    }),
    bookflag: state.bookflag,
    bookwithchild: state.konfig.bookwithchild?1:0,
    sessionid: state.sessionid,
  }
  
  const postres = await SendPostSync(state, setState, 'setbooking', senddata, async (data, setState, state)=>{
    var ret = {};
    if (data.bookingResult) {
      var stat = {};
      if (data.bookingResult.fehler===101) {
        ret.action_intent = data.bookingResult.fehlerdaten;
        stat.status = oldStatus;
      }
      else if (data.bookingResult.fehler) {
        var fehlertext = st['bwh.errormessage.booking.' + data.bookingResult.fehler];
        if (fehlertext.indexOf('{FEHLER}') >= 0) {
          if (data.bookingResult.fehlerdaten) {
            fehlertext = fehlertext.replace('{FEHLER}', data.bookingResult.fehlerdaten);
          }
          else {
            fehlertext = fehlertext.replace('{FEHLER}', '');
          }
        }
        stat.error = fehlertext;
        stat.status = oldStatus;
      }
      else {
        sendAnalyticsEvent(state, '04_Booking-Complete');
        stat.resnr_booked = data.bookingResult.res_nr;
        if (data.error === 0) {
          window.history.pushState && window.history.pushState({status:'gebucht'}, 'gebucht', '#gebucht');        
          sendAnalyticsPage(state, 'Booking-5-Confirm');
          stat.bookConfirmText = data.bookingResult.confirmtext;
          stat.rescrypt = data.bookingResult.rescrypt;
          stat.justBooked = true;
          getBookings(data.bookingResult.rescrypt);
        }
      }
      setState(stat);
    }
    else {
      setState({status: oldStatus, error:'', servererror: true});
    }
    return ret;
  })
  return postres;
}
const onlineCheckin = async (appState, setState, checkindata) => {
  var oldStatus = appState.status;
  var senddata = {
    rescrypt: appState.rescrypt,
    checkindata: JSON.stringify(checkindata),
    sessionid: appState.sessionid,
  }
  
  const postres = await SendPostSync(appState, setState, 'checkin', senddata, async (data, setState, state)=>{
    var ret = {};
    if (data.success) {
      setState({error:'', checkin: 2});
    }
    else {
      setState({status: oldStatus, error:'', servererror: true});
    }
    return ret;
  })
  return postres;
}
const verifyLogin = (state, setState, getBookings) => {
  SendPost(state, setState, 'verifylogindata', {
      resnr:state.login_resnr, 
      email: state.login_email}
  , (data, setState)=>{
    if (data.success) {
      if (data.errid===0) {
        setState({changeerror: false, pop_header:0, rescrypt: data.rescrypt}, getBookings);
      }
      else {
        setState({changeerror: data.errid, pop_header:1});
      }
    }
    else {
      setState({servererror: true});
    }
  });
}
const cancelReservation = (state, setState) => {
  SendPost(state, setState, 'cancelreservation', {
      rescrypt:state.rescrypt, 
      priceagent: state.priceagentCode,
      sprache: state.sprache,
      defaultsprache: state.konfig.defaultsprache}
  , (data, setState)=>{
    if (data.success) {
      setState({cancelTitel: data.titel , cancelText: data.text, status: 'canceled'});
    }
    else {
      setState({servererror: true});
    }
  });
}
const checkCode = (state, setState, doUpdate, doReloadKonfigs) => {
  SendPost(state, setState, 'checkcode', {coupon: state.filterCoupon}, (data, setState)=>{
    if (data.success) {
      var ag = data.force_agent;
      if (!ag) {
        ag = state.startagent;
      }
      var call = null;
      if (state.validCode!==data.valid) {
        call = doUpdate;
      }
      if (state.priceagentCode!==ag) {
        call = doReloadKonfigs;
      }
      setState({validCode: data.valid, priceagentCode:ag}, call);
    }
  });
}

const logtext = (state, text) => {
  SendPost(state, null, 'logtext', {logtext: text});
}

const getCalendar = (state, setState, md) => {
  if (!window.calendarDateRangesCache) window.calendarDateRangesCache = {};
  if (window.calendarDateRangesCache[md]) return;

  window.calendarDateRangesCache[md] = true;

  SendPost(state, setState,'getcalendar', {md:md}, (data, setState)=>{
    const dateRanges = data.map((r)=>{return { state: r.state, range:moment.range(moment(r.range[0]), moment(r.range[1]))}});
    
    window.calendarDateRangesCache[md] = dateRanges;
    setState((state) => {
      const sort = Object.keys(window.calendarDateRangesCache).sort();
      let data = [];
      sort.forEach( (dat) => {
        if (window.calendarDateRangesCache[dat] !== true) {
          data = [ ...data, ...window.calendarDateRangesCache[dat] ];
        }
      }) 
      return {calendarDateRanges: data}
    });
  });   
  
}



export { loadKonfigs, loadSprache, getBookingdata, getAngebote, setBooking, onlineCheckin, verifyLogin, cancelReservation, checkCode, logtext, getCalendar };